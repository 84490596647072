export function sortingOptions(translations) {
  return [{
    title: translations.products.sorting_most_relevant,
    value: 'relevancy-asc'
  }, {
    title: translations.products.sorting_name_asc,
    value: 'name-asc'
  }, {
    title: translations.products.sorting_name_desc,
    value: 'name-desc'
  }, {
    title: translations.products.sorting_price_asc,
    value: 'price-asc'
  }, {
    title: translations.products.sorting_price_desc,
    value: 'price-desc'
  }, {
    title: translations.products.sorting_most_recent,
    value: 'created_at-desc'
  }]
  // {
  //   title: translations.products.sorting_popularity_asc,
  //   value: 'popularity-asc'
  // }]
}

export function pagingOptions() {
  return [{
    title: '12',
    value: '12'
  }, {
    title: '24',
    value: '24'
  }, {
    title: '48',
    value: '48'
  }]
}
