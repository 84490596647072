import React, { useState, useEffect } from "react"
import Select from "react-select"
import { SliderImages } from '@reactiveonline/frontend_shared_components'

import ProductShowBottom from "./ProductShowBottom"
import AddToCart from "./AddToCart"
import ProductStock from "./ProductStock"
import { customReactSelectStyles, customReactSelectTheme, getTranslatableField } from "../../helpers/utils"
import { loadProductViewAnalytics } from '../../helpers/analytics'

export default function ModalProduct({ modalVariant, appProps, currency, modalCategory, isBundleItem, isBundle }) {
  const variant = modalVariant

  const upcEntry    = variant.attributes.filter(attr => attr.prototypeAttributeKey === "UPC")[0]
  const brandEntry  = variant.attributes.filter(attr => attr.prototypeAttributeKey === "Brand")[0]
  let upcValue = ''
  if(upcEntry) {
    upcValue = getTranslatableField(upcEntry, 'title', appProps.currentLocale, false)
  }
  let variantImages = variant.images.length > 0 ? variant.images.map( image => {
    return {
      original: image.preview,
      thumbnail: image.thumbnail,
      fullscreen: image.banner,
      originalAlt: image.file_name,
      thumbnailAlt: image.file_name,
      originalTitle: image.file_name
    }
  }
  ) : [{
    original: 'https://storage.googleapis.com/reactive_files_eu/no-image.jpg',
    thumbnail: 'https://storage.googleapis.com/reactive_files_eu/no-image.jpg',
    fullscreen: 'https://storage.googleapis.com/reactive_files_eu/no-image.jpg',
    originalAlt: 'No Image',
    thumbnailAlt: 'No Image',
    originalTitle: 'No Image'
  }]

  const [selectedOptionsIds, setSelectedOptionsIds] = useState([])
  const [selectedQuantity, setSelectedQuantity] = useState(1)

  useEffect(() => {
    loadProductViewAnalytics(variant, appProps)
  }, []);

  const variantSubtitle = getTranslatableField(variant, 'subtitle', appProps.currentLocale, false)
  let defaultQuantityOption = {value: 1, label: '1'}

  return (
    <>
      <div className='quick-view-product-wrapper flex-box flex-wrap'>
        <div className="product-image">
          <SliderImages
            images={ variantImages }
            showThumbnails={ false }
            showFullscreenButton={ false }
            showNav={ false }
            showBullets={ false }
            lightbox={ false }
            thumbnailPosition={ 'bottom' }
          />
          { variant.onSale &&
            <div className='on-sale-badge flex-box items-center content-center'>
              { variant.onSale && `-${Math.round(variant.onSalePercentage)}%` }
            </div>
          }
        </div>
        <div className="product-details">
          <div className="product-info">
            <h1>
              { getTranslatableField(variant, 'title', appProps.currentLocale, false) }
            </h1>

            { variantSubtitle && variantSubtitle !== '' &&
              <div dangerouslySetInnerHTML={{__html: variantSubtitle}}></div>
            }

            { variant.webshorts && variant.webshorts[appProps.currentLocale] && variant.webshorts[appProps.currentLocale].length > 0 &&
              <div style={{ marginBottom: 15 }}>
                { variant.webshorts[appProps.currentLocale].join(', ') }
              </div>
            }

            { !isBundleItem && variant.showPrice &&
              <>
                <div className='display-price-wrapper flex-box items-center'>
                  <div className='display-price'>
                    { variant.displayTotalPrice }
                  </div>
                  { variant.onSale &&
                    ( appProps.showTextInsteadOfCompareToPrice ?
                      <div className='compare-to-price-text'>
                        { appProps.translations.products.discount_price }
                      </div> :
                      <div className='compare-to-price'>
                        { variant.displayCompareToPrice }
                      </div>
                    )
                  }
                </div>
                { variant.hasTax && !variant.appliedTax.includedInPrice &&
                  <div className="tax-amount">
                    { `(${appProps.translations.products.includes} ${variant.appliedTax.title} ${Math.round(variant.appliedTax.rate * 100)}%)` }
                  </div>
                }
              </>
            }

            { variant.sku &&
              <div className='product-code'>
                <div className='label'>{ appProps.translations.products.sku }:</div>
                <div className='value'>{ variant.sku }</div>
              </div>
            }
            { upcValue &&
              <div className='product-code'>
                <div className='label'>{ appProps.translations.products.upc }:</div>
                <div className='value'>{ upcValue }</div>
              </div>
            }
            { brandEntry && brandEntry.productAttributeImageSource &&
              <div className="flex-box">
                <img
                  src={ brandEntry.productAttributeImageSource }
                  style={{
                    width: "120px",
                    height: "100%",
                    objectFit: "contain",
                    marginBottom: 10
                  }}
                  alt={ getTranslatableField(brandEntry, 'title', appProps.currentLocale, false) }
                />
              </div>
            }
            { !isBundleItem && appProps.stockAllowed && appProps.showStockInfo &&
              <ProductStock
                appProps={ appProps }
                stock={ variant.stock }
                inStock={ variant.inStock }
              />
            }

            { variant.options.map((option, index) => (
              <div className="product-code" key={index}>
                <div className='label'>{ option.optionType }:</div>
                <div className='value'>{ option.name }</div>
              </div>
              )
            )}
          </div>

          { !isBundleItem &&
            <div className="product-options">
              {(!appProps.stockAllowed || (appProps.stockAllowed && variant.inStock && variant.showPrice)) &&
                <div className="product-option-wrapper quantity-dropdown-wrapper">
                  <div className='option-type-title-wrapper '>
                    { appProps.translations.cart.quantity }
                  </div>
                  <Select
                    theme={ customReactSelectTheme }
                    styles={ customReactSelectStyles }
                    isSearchable={ false }
                    options={ Array(variant.totalStock === null || variant.totalStock > appProps.maxProductQuantity ? appProps.maxProductQuantity : variant.totalStock).fill().map((x, i) => {
                      return {value: i+1, label: `${i+1}`}
                    })}
                    defaultValue={ defaultQuantityOption }
                    onChange={(selected) => {
                      setSelectedQuantity(selected.value)
                    }}
                  />
                </div>
              }

              {(!appProps.stockAllowed || (appProps.stockAllowed && variant.inStock)) && !isBundleItem &&
                <AddToCart
                  appProps={ appProps }
                  productId={ variant.id }
                  selectedOptionsIds={ selectedOptionsIds }
                  quantity={ selectedQuantity }
                  addCartText={ appProps.translations.products.add_to_cart_button }
                  currency={ currency }
                  product={ variant }
                  isBundle={ isBundle }
                />
              }
            </div>
          }
        </div>
      </div>

      <div style={{ marginTop: 40 }}>
        <ProductShowBottom
          appProps={ appProps }
          product={ variant }
          category={ modalCategory }
          productPageBottomLayout={ 'tabbed' }
          isBundle={ isBundle }
          isBundleItem={ isBundleItem }
        />
      </div>
    </>
  )
}
