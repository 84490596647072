import React, { useState, useEffect, useRef } from "react"
import Select from "react-select"
import { SliderImages } from '@reactiveonline/frontend_shared_components'

import ProductDescription from "./ProductDescription"
import Options from "./Options"
import ProductShowBottom from "./ProductShowBottom"
import RelatedProducts from "./RelatedProducts"
import SimilarProducts from "./SimilarProducts"
import AddToCart from "./AddToCart"
import ProductStock from "./ProductStock"
import ProductItem from "../products/ProductItem"
import { customReactSelectStyles, customReactSelectTheme, getTranslatableField } from "../../helpers/utils"
import { loadProductViewAnalytics } from '../../helpers/analytics'
import moment from 'moment'

export default function Product({
  appProps, product, category, currency, isOnSlider, productsPerRowClass,
  productPageLayout, productPageBottomLayout, addCartText, similarItemsPath, hasBuilderContent,
  getBuilderContentUrl, relatedProductsPath
}) {
  const {
    maxProductQuantity, currentLocale, translations, thumbnailsPosition,
    stockAllowed, showStockInfo
  } = appProps
  const upcEntry    = product.attributes.filter(attr => attr.prototypeAttributeKey === "UPC")[0]
  const brandEntry  = product.attributes.filter(attr => attr.prototypeAttributeKey === "Brand")[0]
  let upcValue = ''
  if(upcEntry) {
    upcValue = getTranslatableField(upcEntry, 'title', currentLocale, false)
  }

  let productImages = product.images.length > 0 ? product.images.map( image => {
    return {
      original: image.preview,
      thumbnail: image.thumbnail,
      fullscreen: image.banner,
      originalAlt: image.file_name,
      thumbnailAlt: image.file_name,
      originalTitle: image.file_name
    }
  }
  ) : [{
    original: 'https://storage.googleapis.com/reactive_files_eu/no-image.jpg',
    thumbnail: 'https://storage.googleapis.com/reactive_files_eu/no-image.jpg',
    fullscreen: 'https://storage.googleapis.com/reactive_files_eu/no-image.jpg',
    originalAlt: 'No Image',
    thumbnailAlt: 'No Image',
    originalTitle: 'No Image'
  }]

  const [displayPrice, setDisplayPrice] = useState(product.displayPrice)
  const [displayTaxPrice, setDisplayTaxPrice] = useState(product.displayTaxPrice)
  const [displayTotalPrice, setDisplayTotalPrice] = useState(product.displayTotalPrice)
  const [displayCompareToPrice, setDisplayCompareToPrice] = useState(product.displayCompareToPrice)
  const [sku, setSku] = useState(product.sku)
  const [upc, setUpc] = useState(upcValue)
  const [inStock, setInStock] = useState(product.inStock)
  const [stock, setStock] = useState(product.totalStock)
  const [onSale, setOnSale] = useState(product.onSale)
  const [onSalePercentage, setOnSalePercentage] = useState(product.onSalePercentage)
  const [loading, setLoading] = useState(false)
  const [selectedOptionsIds, setSelectedOptionsIds] = useState([])
  const [selectedQuantity, setSelectedQuantity] = useState(1)
  const [images, setImages] = useState(productImages)
  const [similarItems, setSimilarItems] = useState([])
  const [relatedProducts, setRelatedProducts] = useState([])
  const [showPrice, setShowPrice] = useState(product.showPrice)

  const [orderStatus, _setOrderStatus] = useState(product.productOrderStatus || {})
  const orderStatusRef = useRef(orderStatus) // must be before maxQuantity

  const [maxQuantity, setMaxQuantity] = useState(() => getMaxQuantity(product.maxPurchasableQuantity))

  const addToCartText = translations.products[(orderStatus.canPreorder && !inStock)? 'preorder_button' : ((orderStatus.canBackorder && !inStock) ? 'backorder_button' : 'add_to_cart_button')]

  useEffect(() => {
    loadProductViewAnalytics(product, appProps)
  }, [])

  useEffect(() => {
    setLoading(true)
    Rails.ajax({
      type: "GET",
      url: similarItemsPath,
      dataType: "json",
      success: res => {
        setSimilarItems(res.items)
      },
      complete: res => {
        setLoading(false)
      }
    })
  }, [])

  useEffect(() => {
    setLoading(true)
    Rails.ajax({
      type: "GET",
      url: `${ relatedProductsPath }?type=product`,
      dataType: "json",
      success: res => {
        setRelatedProducts(res.items)
      },
      complete: res => {
        setLoading(false)
      }
    })
  }, [])

  function setOrderStatus(state) {
    _setOrderStatus(state)
    orderStatusRef.current = state
  }

  function getMaxQuantity(maxPurchasableQuantity) {
    if (orderStatusRef.current.isOrderable) {
      return maxPurchasableQuantity || maxProductQuantity
    }

    if (maxProductQuantity && maxPurchasableQuantity) {
      return Math.min(...[maxProductQuantity, maxPurchasableQuantity])
    }
    return Math.max(...[maxProductQuantity, maxPurchasableQuantity])
  }

  function updateStateProduct(pricesData) {
    let variantImages = pricesData.images ? pricesData.images.map(image => {
      return {
        original: image.preview,
        thumbnail: image.thumbnail,
        fullscreen: image.banner,
      }
    }) : []

    let mergedImages = variantImages.concat(productImages)

    if(mergedImages.length > 1){
      mergedImages = mergedImages.filter(img => img.original !== 'https://storage.googleapis.com/reactive_files_eu/no-image.jpg')
    }

    const upcEntry = product.attributes.filter(attr => attr.prototypeAttributeKey === "UPC")[0]
    let upcValue = ''
    if(upcEntry) {
      upcValue = getTranslatableField(upcEntry, 'title', currentLocale, false)
    }

    setDisplayPrice(pricesData.displayPrice)
    setDisplayTaxPrice(pricesData.displayTaxPrice)
    setDisplayTotalPrice(pricesData.displayTotalPrice)
    setDisplayCompareToPrice(pricesData.displayCompareToPrice)
    setSku(pricesData.sku || product.sku)
    setUpc(pricesData.upc || upcValue)
    setInStock('inStock' in pricesData ? pricesData.inStock : product.inStock)
    setStock('totalStock' in pricesData ? pricesData.totalStock : product.totalStock)
    setOnSale('onSale' in pricesData ? pricesData.onSale : product.onSale)
    setOnSalePercentage('onSalePercentage' in pricesData ? pricesData.onSalePercentage : product.onSalePercentage)
    setSelectedQuantity(1)
    setImages(mergedImages)
    setShowPrice(pricesData.showPrice)
    setOrderStatus(('productOrderStatus' in pricesData ? pricesData.productOrderStatus : product.productOrderStatus) || {}) // save before setMaxQuantity so getMaxQuantity can get the new state
    setMaxQuantity('maxPurchasableQuantity' in pricesData ? getMaxQuantity(pricesData.maxPurchasableQuantity) : getMaxQuantity(product.maxPurchasableQuantity))
    setLoading(false)
  }

  function updateStateSelectedOptionsIds(optionsIds) {
    setSelectedOptionsIds(optionsIds)
  }

  const defaultQuantityOption = {value: 1, label: '1'}

  const productDescription = getTranslatableField(product, 'description', currentLocale, false)
  const productSubtitle = getTranslatableField(product, 'subtitle', currentLocale, false)

  return (
    <>
      <div id='product_page_above_product'></div>
      <div className={ `product-show-wrapper ${productPageLayout != 'image_top' && 'main-container'} ${productPageLayout}` }>
        <div className="product-image" style={{ position: 'relative' }}>
          <SliderImages
            images={ images }
            showThumbnails={ images && images.length > 1 && true }
            showFullscreenButton={ false }
            showNav={ images && images.length > 1 && true }
            showBullets={ images && images.length > 1 && true }
            lightbox={ true }
            thumbnailPosition={ images && images.length > 1 ? thumbnailsPosition : 'bottom' }
          />


          { product.badges &&
            <div className='flex-box items-center product-badges-wrapper'>
              { product.badges && product.badges.map( (badge, index) => (
                <img
                  key={ index }
                  src={ badge.source }
                  className='product-badge'
                />
              ))}
            </div>
          }

          { onSale &&
            <div className='on-sale-badge flex-box items-center content-center'>
              { onSale && `-${Math.round(onSalePercentage)}%` }
            </div>
          }
        </div>
        <div className={`product-details ${productPageLayout === 'image_top' && 'main-container'}`}>
          <div className="product-info">
            <h1>
              { getTranslatableField(product, 'title', currentLocale, false) }
            </h1>
            <div id='product_page_below_title'></div>

            { productSubtitle && productSubtitle !== '' &&
              <div dangerouslySetInnerHTML={{__html: productSubtitle}}></div>
            }

            { product.webshorts[currentLocale] && product.webshorts[currentLocale].length > 0 &&
              <div style={{ marginBottom: 15 }}>
                { product.webshorts[currentLocale].join(', ') }
              </div>
            }

            { showPrice &&
              <>
                <div className='display-price-wrapper flex-box items-center'>
                  <div className='display-price'>
                    { displayTotalPrice }
                  </div>
                  { onSale &&
                  ( appProps.showTextInsteadOfCompareToPrice ?
                    <div className='compare-to-price-text'>
                      { appProps.translations.products.discount_price }
                    </div> :
                    <div className='compare-to-price'>
                      { displayCompareToPrice }
                    </div>
                  )
                  }
                </div>
                { product.hasTax && !product.appliedTax.includedInPrice &&
                  <div className="tax-amount">
                    { `(${translations.products.includes} ${product.appliedTax.title} ${Math.round(product.appliedTax.rate * 100)}%)` }
                  </div>
                }
              </>
            }
            <div id='product_page_below_price'></div>

            { sku &&
              <div className='product-code'>
                <div className='label'>{ translations.products.sku }:</div>
                <div className='value'>{ sku }</div>
              </div>
            }
            { upc &&
              <div className='product-code'>
                <div className='label'>{ translations.products.upc }:</div>
                <div className='value'>{ upc }</div>
              </div>
            }
            { brandEntry && brandEntry.productAttributeImageSource &&
              <div className="flex-box">
                <img
                  src={ brandEntry.productAttributeImageSource }
                  style={{
                    width: "120px",
                    height: "100%",
                    objectFit: "contain",
                    marginBottom: 10
                  }}
                  alt={ getTranslatableField(brandEntry, 'title', currentLocale, false) }
                />
              </div>
            }
            { stockAllowed && showStockInfo &&
              <ProductStock
                appProps={ appProps }
                stock={ stock }
                inStock={ inStock }
              />
            }
          </div>

          <div className="product-options-wrapper">
            <Options
              appProps={ appProps }
              loading={ loading }
              optionTypes={ product.optionTypes }
              updateStateProduct={ updateStateProduct }
              setLoading={ setLoading }
              productId={ product.id }
              updateStateSelectedOptionsIds={ updateStateSelectedOptionsIds }
            />
            <div className="product-options">
              { showPrice && (!stockAllowed || (stockAllowed && inStock) || orderStatusRef.current.isOrderable) &&
                <div className="product-option-wrapper quantity-dropdown-wrapper">
                  <div className='option-type-title-wrapper'>
                    { translations.cart.quantity }
                  </div>
                  <Select
                    theme={customReactSelectTheme}
                    styles={customReactSelectStyles}
                    isSearchable={ false }
                    options={ Array(stock === null || (stock > maxQuantity || orderStatusRef.current.isOrderable) ? maxQuantity : stock).fill().map((x, i) => {
                      return {value: i+1, label: `${i+1}`}
                    })}
                    defaultValue={defaultQuantityOption}
                    onChange={(selected) => {
                      setSelectedQuantity(selected.value)
                    }}
                  />
                </div>
              }
              <div id='product_page_below_quantity'></div>

              { showPrice && (!stockAllowed || (stockAllowed && inStock) || orderStatus.isOrderable) &&
                <AddToCart
                  appProps={ appProps }
                  productId={ product.id }
                  selectedOptionsIds={ selectedOptionsIds }
                  quantity={ selectedQuantity }
                  addCartText={ addToCartText }
                  currency={ currency }
                  product={ product }
                />
              }

              { !inStock && orderStatus.availableOn &&
                <div className='product-available-date flex-box content-center'>
                  { translations.products.available_on }: { moment(new Date(orderStatus.availableOn)).format('DD/MM/YYYY') }
                </div>
              }
            </div>
          </div>
        </div>
      </div>

      <ProductShowBottom
        appProps={ appProps }
        product={ product }
        category={ category }
        productPageBottomLayout={ productPageBottomLayout }
        hasBuilderContent={ hasBuilderContent }
        getBuilderContentUrl={ getBuilderContentUrl }
        hasContainer={ true }
      />

      <div id='product_page_above_similars'></div>

      { similarItems.length > 0 &&
        <SimilarProducts
          appProps={ appProps }
          similarProducts={ similarItems }
        />
      }

      <div id='product_page_above_related'></div>

      { relatedProducts.length > 0 &&
        <RelatedProducts
          appProps={ appProps }
          relatedProducts={ relatedProducts }
        />
      }

    </>
  )
}
